// @mui material components
import Card from "@mui/material/Card";

// Knoone Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knoone Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import SoftButton from "components/SoftButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { useEffect, useState } from "react";
import Table from "examples/Tables/Table";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { useSoftUIController, startLoading, setLoading, setUsers } from "context";
import React from "react";
import { getConsultationByType } from "Services/endpointes";
import { setConsultation } from "context";
import { createConsultation } from "Services/endpointes";
import ConsultationForm from "./form";
import { setDialog } from "context";
import ConsultationView from "./data/consultation";
import Footer from "examples/Footer";
import { NavLink } from "react-router-dom";

function Consultation() {
  const [controller, dispatch] = useSoftUIController();
  const [page, setPage] = React.useState(0);
  const [select, setSelect] = React.useState("incentive");
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { consultation } = controller;

  const getAllConsultations = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataByParam(getConsultationByType, select);
      if (response.status === 200) {

        setConsultation(dispatch, response.data);
        toast.success(response?.message);
      }
      else {
        toast.warn(response?.message);
        setLoading(dispatch, false);
      }
    } catch (error) {
      setLoading(dispatch, false);
      toast.warn(error.response?.data?.message ?? "Network Error!");
    }
  };
  useEffect(() => {
    consultation.length < 1 && getAllConsultations();
  }, [select]);

  const setSelector = (selector) => {
    setSelect(selector);
    setConsultation(dispatch, []);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addConsultation = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createConsultation, formData);
      getAllConsultations();
      setDialog(dispatch, [response]);
    } catch (error) {
      toast.error(error?.response?.data.message);
      setLoading(dispatch, false);
    }
  };
  let memoizedRows = ConsultationView.rows(consultation, dispatch, getAllConsultations);
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => {
    if (menu === currentTarget) {
      closeMenu();
    } else {
      setMenu(currentTarget);
    }
  };
  const closeMenu = () => setMenu(null);
  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"consultation"} call={getAllConsultations} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.5rem"
              height="3.5rem"
              bgColor="white"
              shadow="sm"
              borderRadius="50%"
              position="fixed"
              right="2rem"
              bottom="2rem"
              zIndex={99}
              color="dark"
              sx={{ cursor: "pointer" }}
              onClick={openMenu}
            >
              <Icon fontSize="default" color="inherit">
                tune
              </Icon>
              <Menu
                id="simple-menu"
                anchorEl={menu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menu)}
                onClose={closeMenu}
              >
                <i
                  onClick={() => {
                    closeMenu();
                    setDialog(dispatch, [
                      {
                        status: "form",
                        call: addConsultation,
                        message: `CREATE NEW CONSULTATION - `,
                        action: "ADD",
                        children: <ConsultationForm data={[]} />,
                      },
                    ]);
                  }}
                >
                  Add Consultation
                </i>

                <MenuItem
                  onClick={() => {
                    closeMenu;
                    setSelector("incentive");
                  }}
                >
                  Incentive
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelector("regular");
                    closeMenu;
                  }}
                >
                  Regular
                </MenuItem>
              </Menu>
            </SoftBox>

            {consultation?.length > 0 ? (
              <>
                <Table columns={ConsultationView.columns} rows={memoizedRows} />
                <SoftBox mt={2} display="block" width={90}>
                  <TablePagination
                    component="span"
                    count={100}
                    page={page}
                    onPageChange={handleChangePage}
                    rowsPerPage={rowsPerPage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                  />
                </SoftBox>
              </>
            ) : (
              <SoftBox mt={4}>
                <SoftBox mb={1.5}>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Grid item container spacing={3}>
                        <Grid item xs={12} xl={12}>
                          <DefaultInfoCard
                            icon="cloud"
                            title={`You Don't have an active connection yet. Add connection to your portfolio and start earning.`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Consultation;
