// @mui material components
import Card from "@mui/material/Card";

// Knoone Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knoone Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import SoftButton from "components/SoftButton";
import {
  Checkbox,
  FormControlLabel,
  Grid,
  Icon,
  Menu,
  MenuItem,
  TablePagination,
} from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { useEffect, useState } from "react";
import Table from "examples/Tables/Table";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { useSoftUIController, startLoading, setLoading } from "context";
import React from "react";
import { getAllRoute } from "Services/endpointes";
import { setRoutes } from "context";
import RoutesView from "./data/appaccess";
import { createRoute } from "Services/endpointes";
import AppAccessForm from "./form";
import { setDialog } from "context";
import Footer from "examples/Footer";

function AppAccess() {
  const [controller, dispatch] = useSoftUIController();
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [select, setSelect] = React.useState();

  const setSelector = (selector) => {
    setSelect(selector);
  };
  const { routes } = controller;
  const getAllRoutes = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getDataWithPagination(getAllRoute, 0, 100);
      setRoutes(dispatch, response.data);
      toast.success(response?.message);
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };
  useEffect(() => {
    routes.length < 1 && getAllRoutes();
  }, []);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const addRoute = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createRoute, formData);
      if (response.status === 200) {
        getAllRoutes();
        setDialog(dispatch, [response]);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setDialog(dispatch, [error?.response?.data]);
      // toast.error(error?."Failed to add source. Please try again later.");
    }
  };
  let memoizedRows = RoutesView.rows(routes, dispatch, getAllRoutes);
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => {
    if (menu === currentTarget) {
      closeMenu();
    } else {
      setMenu(currentTarget);
    }
  };
  const closeMenu = () => setMenu(null);

  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"appaccess"} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.5rem"
              height="3.5rem"
              bgColor="white"
              shadow="sm"
              borderRadius="50%"
              position="fixed"
              right="2rem"
              bottom="2rem"
              zIndex={99}
              color="dark"
              sx={{ cursor: "pointer" }}
              onClick={openMenu}
            >
              <Icon fontSize="default" color="inherit">
                tune
              </Icon>
              <Menu
                id="simple-menu"
                anchorEl={menu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menu)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setDialog(dispatch, [
                      {
                        call: addRoute,
                        status: "form",
                        message: "CREATE NEW ROUTE",
                        action: "Add New",
                        children: <AppAccessForm />,
                      },
                    ]);
                  }}
                >
                  Add Route
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    closeMenu;
                    setSelector("users");
                  }}
                >
                  Users
                </MenuItem>
                <MenuItem
                  onClick={() => {
                    setSelector("staff");
                    closeMenu;
                  }}
                >
                  Administrator
                </MenuItem>
              </Menu>
            </SoftBox>

            {routes?.length > 0 ? (
              <Table columns={RoutesView.columns} rows={memoizedRows} />
            ) : (
              <SoftBox mt={4}>
                <SoftBox mb={1.5}>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Grid item container spacing={3}>
                        <Grid item xs={12} xl={12}>
                          <DefaultInfoCard
                            icon="cloud"
                            title={`You Don't have an active connection yet. Add connection to your portfolio and start earning.`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default AppAccess;
