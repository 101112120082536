import PropTypes from "prop-types";

// @mui material components
import Link from "@mui/material/Link";

// Knoone Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";

// Knoone Dashboard React base styles
import typography from "assets/theme/base/typography";
import { LocalPhone, Mail } from "@mui/icons-material";

function Footer({ company, links }) {
  const { href, name } = company;
  const { size } = typography;

  return (
    <div>
      <SoftBox
        display="flex"
        flexDirection={{ xs: "column", lg: "row" }}
        justifyContent="space-between"
        alignItems="center"
        px={1.5}
        mt={4}
        mb={2}
      >
        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          <SoftBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}>
            <img
              src="favicon.png"
              alt="Logo"
              style={{ height: "40px", width: "auto", marginRight: "5px" }}
            />
          </SoftBox>
        </SoftBox>

        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          &copy; {new Date().getFullYear()}, Developed by
          <SoftBox fontSize={size.md} color="text" mb={-0.5} mx={0.25}></SoftBox>
          <Link href={href} target="_blank" cursior="pointer">
            <SoftTypography variant="button" fontWeight="medium" cursior="pointer">
              &nbsp;{name}&nbsp;
            </SoftTypography>
          </Link>
        </SoftBox>

        <SoftBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
          color="text"
          fontSize={size.sm}
          px={1.5}
        >
          <SoftBox fontSize={1} display="flex" color="text" mb={-0.5} mx={0.25}>
            <SoftTypography
              cursor="pointer"
              fontSize="0.9rem"
              style={{ margin: "0 10px", listStyle: "none" }}
            >
              <LocalPhone style={{ marginRight: "5px" }} />
              <a href="tel:+919815117124">+91 98151-17124</a>
            </SoftTypography>
            <SoftTypography
              color="dark"
              to="mailto:info@knoone.com"
              cursor="pointer"
              fontSize="0.9rem"
              style={{ margin: "0 10px", listStyle: "none" }}
            >
              <Mail verticalAlign="middle" style={{ marginRight: "5px" }} />
              <a href="mailto:info@knoone.com">info@knoone.com</a>
            </SoftTypography>
          </SoftBox>
        </SoftBox>
      </SoftBox>
    </div>
  );
}

// Setting default values for the props of Footer
Footer.defaultProps = {
  company: { href: "https://knoone.com/", name: "Kno-one India Limited" },
  links: [
    // { href: "", name: "9875982211" },
    // { href: "", name: "Blog" },
    // { href: "", name: "License" },
  ],
};

// Typechecking props for the Footer
Footer.propTypes = {
  company: PropTypes.objectOf(PropTypes.string),
  links: PropTypes.arrayOf(PropTypes.object),
};

export default Footer;
