// @mui material components
import Card from "@mui/material/Card";

// Knoone Dashboard React components
import SoftBox from "components/SoftBox";

// Knoone Dashboard React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";

// Data
import { Grid, Icon, Menu, MenuItem } from "@mui/material";
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";

import { useEffect, useState } from "react";
import { useSoftUIController, setRoyality, setLoading } from "context";
import Table from "examples/Tables/Table";
import ApiClient from "Services/ApiClient";
import { toast } from "react-toastify";
import { startLoading } from "context";
import React from "react";
import royalityView from "./data/royality";
import { getRoyalties } from "Services/endpointes";
import Footer from "examples/Footer";
import { createRoyality } from "Services/endpointes";
import { setDialog } from "context";
import RoyalityForm from "./form";

function Royality() {
  const [controller, dispatch] = useSoftUIController();
  const { royality } = controller;

  const getRoyality = async () => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.getData(getRoyalties);
      if (response.status == 200) {
        setRoyality(dispatch, response?.data);
      }
      setDialog(dispatch, response);
      toast.success(response?.message);
    } catch (error) {
      setLoading(dispatch, false);
      toast.error(error.response?.data?.message ?? "Oops! Something went wrong, please try later");
    }
  };

  const memoizedRows = royalityView.rows(royality, dispatch, getRoyality);
  const addRoyality = async (formData) => {
    startLoading(dispatch, true);
    try {
      const response = await ApiClient.createData(createRoyality, formData);
      if (response.status === 200) {
        getRoyality();
        setDialog(dispatch, [response]);
      } else {
        setDialog(dispatch, [response]);
      }
    } catch (error) {
      setDialog(dispatch, [error?.response?.data]);
      // toast.error(error?."Failed to add source. Please try again later.");
    }
  };
  const [menu, setMenu] = useState(null);
  const openMenu = ({ currentTarget }) => {
    if (menu === currentTarget) {
      closeMenu();
    } else {
      setMenu(currentTarget);
    }
  };
  const closeMenu = () => setMenu(null);
  useEffect(() => {
    Royality.length < 1 && getRoyality();
  }, []);
  return (
    <DashboardLayout>
      <DashboardNavbar endpoint={"royality"} call={getRoyality} />
      <SoftBox py={3}>
        <SoftBox mb={3}>
          <Card>
            <SoftBox
              display="flex"
              justifyContent="center"
              alignItems="center"
              width="3.5rem"
              height="3.5rem"
              bgColor="white"
              shadow="sm"
              borderRadius="50%"
              position="fixed"
              right="2rem"
              bottom="2rem"
              zIndex={99}
              color="dark"
              sx={{ cursor: "pointer" }}
              onClick={openMenu}
            >
              <Icon fontSize="default" color="inherit">
                tune
              </Icon>
              <Menu
                id="simple-menu"
                anchorEl={menu}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                open={Boolean(menu)}
                onClose={closeMenu}
              >
                <MenuItem
                  onClick={() => {
                    closeMenu();
                    setDialog(dispatch, [
                      {
                        call: addRoyality,
                        status: "form",
                        message: "CREATE NEW ROYALITY",
                        action: "Add New",
                        children: <RoyalityForm />,
                      },
                    ]);
                  }}
                >
                  Add Royality
                </MenuItem>
              </Menu>
            </SoftBox>

            {royality?.length > 0 ? (
              <Table columns={royalityView.columns} rows={memoizedRows} />
            ) : (
              <SoftBox mt={4}>
                <SoftBox mb={1.5}>
                  <Grid container spacing={3}>
                    <Grid item lg={12}>
                      <Grid item container spacing={3}>
                        <Grid item xs={12} xl={12}>
                          <DefaultInfoCard
                            icon="cloud"
                            title={`You Don't have an active connection yet. Add connection to your portfolio and start earning.`}
                          />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </SoftBox>
              </SoftBox>
            )}
          </Card>
        </SoftBox>
      </SoftBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Royality;
