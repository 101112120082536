/* eslint-disable react/prop-types */
// Knoone Dashboard React components
import SoftBox from "components/SoftBox";
import SoftTypography from "components/SoftTypography";
import SoftBadge from "components/SoftBadge";

import { Icon } from "@mui/material";
import { setDialog } from "context";
import RewardsForm from "../form";
import { updateReward } from "Services/endpointes";
import { toast } from "react-toastify";
import ApiClient from "Services/ApiClient";
import { deleteReward } from "Services/endpointes";

function Author({ name, id }) {
  return (
    <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
      <SoftBox display="flex" flexDirection="column">
        <SoftTypography variant="button" fontWeight="medium">
          {name}
        </SoftTypography>
        <SoftTypography variant="caption" color="secondary">
          {id}
        </SoftTypography>
      </SoftBox>
    </SoftBox>
  );
}

function Status({ tnxId, status }) {
  if (tnxId === null) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" alignItems="center" flexDirection="column" gap="4px">
          <SoftBadge
            variant="gradient"
            badgeContent="payment pending"
            color="warning"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  } else if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge variant="gradient" badgeContent="Inactive" color="warning" size="xs" container />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge variant="gradient" badgeContent="Active" color="success" size="xs" container />
        </SoftBox>
      </SoftBox>
    );
  }
}

function Verified({ status }) {
  if (!status) {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBadge
          variant="gradient"
          badgeContent="Not Verified"
          color="error"
          size="xs"
          container
        />
      </SoftBox>
    );
  } else {
    return (
      <SoftBox display="flex" alignItems="center" px={1} py={0.5}>
        <SoftBox display="flex" flexDirection="column">
          <SoftBadge
            variant="gradient"
            badgeContent="Verified"
            color="success"
            size="xs"
            container
          />
        </SoftBox>
      </SoftBox>
    );
  }
}
const editReward = (id, getAllRewards) => async (formData) => {
  try {
    formData.append("id", id);
    console.log(id, formData, formData.get("id"));
    const response = await ApiClient.putData(updateReward, formData);
    toast.success(response.message);
    getAllRewards();
  } catch (error) {
    toast.error(
      error.response?.data?.message ?? "Failed to update Reward. Please try again later."
    );
  }
};

const removeReward = (id, getAllRewards) => async (form) => {
  try {
    const response = await ApiClient.deleteData(deleteReward, id);
    toast.success(response?.message);
    getAllRewards();
  } catch (error) {
    toast.error(
      error.response?.data?.message ?? "Failed to delete Rewards. Please try again later."
    );
  }
};
const reawrdView = {
  columns: [
    { name: "range", align: "left" },
    { name: "rule", align: "left" },
    { name: "type", align: "center" },
    { name: "status", align: "center" },
    { name: "reward", align: "center" },
    { name: "createdAt", align: "center" },
    { name: "actions", align: "center" },
  ],

  rows: (data, dispatch, getAllRewards) => {
    return data.map((e) => {
      console.log(e);
      const dateObject = new Date(e.createdAt);

      const options = { day: "2-digit", month: "2-digit", year: "2-digit" };
      const formattedDate = dateObject.toLocaleDateString("en-GB", options);

      return {
        range: <Author name={e.range} />,
        rule: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.rule}
          </SoftTypography>
        ),
        type: <Author name={e.type} />,

        status: <Status status={e.status} />,

        reward: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.reward}
          </SoftTypography>
        ),
        createdAt: (
          <SoftTypography variant="caption" color="secondary" fontWeight="medium">
            {e.createdAt}
          </SoftTypography>
        ),
        actions: (
          <SoftBox
            display="flex"
            alignItems="center"
            justifyContent="center"
            gap="10px"
            px={1}
            py={0.5}
          >
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="info"
              fontWeight="medium"
              cursor="pointer"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    route: "",
                    call: editReward(e.id, getAllRewards),
                    message: `UPDATE - CONNECTION - ${e.userId}`,
                    action: "Update",
                    children: <RewardsForm data={e} />,
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="info">
                edit
              </Icon>
            </SoftTypography>
            <SoftTypography
              component="a"
              href="#"
              variant="caption"
              color="error"
              cursor="pointer"
              fontWeight="medium"
              onClick={() => {
                setDialog(dispatch, [
                  {
                    status: "form",
                    route: "",
                    call: removeReward(e.id, getAllRewards),
                    message: `DELETE - REWARD - ${e.type}`,
                    action: "Delete",
                  },
                ]);
              }}
            >
              <Icon fontSize="small" color="error">
                delete
              </Icon>
            </SoftTypography>
          </SoftBox>
        ),
      };
    });
  },
};

export default reawrdView;
